import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Loading from "components/shared/Loading";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";

import { useArticleReader } from "database/articles/useArticleReader";
import useBookingWriter from "database/bookings/useBookingWriter";

import { Button } from "components/shared/standardElements";
import ScrollArea from "components/shared/ScrollArea";
import OrderCard from "components/shared/sharedOrderTab/OrderCard";
import CreateEditOrderNameModal from "components/shared/sharedOrderTab/CreateEditOrderNameModal";
import OrderTabCartModal from "components/shared/sharedOrderTab/OrderTabCartModal";

import { useDatabaseReader } from "database/useDatabaseReader";
import useOrderWriter from "database/orders/useOrderWriter";
import OrderSummaryModal from "components/shared/sharedOrderTab/OrderSummaryModal";
import ConfirmModal from "components/shared/ConfirmModal";
import { generateOrderForm } from "components/shared/downloads/createOrderForms";
import OrderSummaryPDF from "components/shared/downloads/OrderSummaryPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import UserNoticeModal from "components/shared/UserNoticeModal";

const OrdersTab = ({ booking, articleTabSelectedOrderID }) => {
  //HOOKS
  const { t } = useTranslation();
  const { deviceID, orders } = useDatabaseReader();
  const { activeSeasons, overallCategories } = useArticleReader();
  const { markBookingComplete } = useBookingWriter();
  const {
    createNewOrder,
    updateOrderName,
    updateDBOrderQtys,
    transferOrderToBuyer,
    duplicateOrder,
    completeOrder,
    setCurrentEditorID
  } = useOrderWriter();

  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
  const [editNameOrder, setEditNameOrder] = useState();
  const [orderForCart, setOrderForCart] = useState();
  const [orderForSummary, setOrderForSummary] = useState();
  const [orderForTransfer, setOrderForTransfer] = useState();
  const [orderForDuplication, setOrderForDuplication] = useState();
  const [orderForDownload, setOrderForDownload] = useState();
  const [sectionedOrders, setSectionedOrders] = useState([]);
  const [showNoOrderFormModal, setShowNoOrderFormModal] = useState(false);

  //Unset editing id on any open orders to keep database in sync
  useEffect(() => {
    //Chrome Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onbeforeunload = (e) => {
      if (orderForCart?.id != null) {
        setCurrentEditorID(null, orderForCart.id);
      }
      if (articleTabSelectedOrderID != null) {
        setCurrentEditorID(null, articleTabSelectedOrderID);
      }
    };
    //Other Browswers Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onunload = (e) => {
      if (orderForCart?.id != null) {
        setCurrentEditorID(null, orderForCart.id);
      }
      if (articleTabSelectedOrderID != null) {
        setCurrentEditorID(null, articleTabSelectedOrderID);
      }
    };
    return () => {
      if (
        orderForCart?.id != null &&
        articleTabSelectedOrderID !== orderForCart?.id
      ) {
        setCurrentEditorID(null, orderForCart.id);
      }
    };
  }, [orderForCart?.id, setCurrentEditorID, articleTabSelectedOrderID]);

  useEffect(() => {
    if (orders == null) return;
    const ordersBySection = {
      repManaged: [],
      userGenerated: [],
      completed: []
    };
    Object.values(orders).forEach((order) => {
      if (order.isRepManaged && order.completionDate == null) {
        ordersBySection.repManaged.push(order);
      } else if (order.completionDate == null) {
        ordersBySection.userGenerated.push(order);
      } else {
        ordersBySection.completed.push(order);
      }
    });
    if (ordersBySection.repManaged.length === 0)
      delete ordersBySection.repManaged;
    if (ordersBySection.userGenerated.length === 0)
      delete ordersBySection.userGenerated;
    if (ordersBySection.completed.length === 0)
      delete ordersBySection.completed;

    setSectionedOrders(ordersBySection);
  }, [orders]);

  const onEditOrder = async (order) => {
    setIsLoading(true);
    setOrderForCart(order);
    await setCurrentEditorID(deviceID, order.id);
    setIsLoading(false);
  };

  const onCloseCart = async () => {
    if (articleTabSelectedOrderID !== orderForCart.id) {
      await setCurrentEditorID(null, orderForCart.id);
    }
    setOrderForCart();
  };

  const onSaveNewName = async (newName) => {
    setIsLoading(true);
    if (editNameOrder == null && showCreateOrderModal === true) {
      await createNewOrder(newName, true);
      setShowCreateOrderModal(false);
    } else {
      await updateOrderName(newName, editNameOrder.id);
      setEditNameOrder();
    }
    setIsLoading(false);
  };

  const onTransferToBuyer = (order) => {
    setOrderForTransfer(order);
  };
  const onConfirmTransferToBuyer = async () => {
    transferOrderToBuyer(orderForTransfer.id);
    setOrderForTransfer();
  };

  const onDuplicateOrder = (order) => {
    setOrderForDuplication(order);
  };
  const onConfirmDuplicateOrder = async (newName) => {
    setIsLoading(true);
    let order = { ...orderForDuplication };
    delete order.id;
    delete order.completionDate;
    order.name = newName;
    order.creationDate = new Date();
    order.isRepManaged = true;
    await duplicateOrder(order);
    setIsLoading(false);
    setOrderForDuplication();
  };

  const onDownloadOrder = async (order) => {
    setOrderForDownload(order);
  };

  const onConfirmDownloadOrder = async () => {
    setIsLoading(true);

    //SETUP ZIP FILE TO HOUSE ALL DOCUMENTS TO DOWNLOAD
    let zip = new JSZip();

    //Create PDF Order Summary
    const OrderSummary = (
      <OrderSummaryPDF
        bookingArticles={booking?.articles}
        order={orderForDownload}
        seasonKey={booking?.seasonKey}
        overallCategories={overallCategories}
        courseName={booking?.courseName}
        priceToShow={booking?.priceToShowOrdering}
      />
    );
    const summaryBlob = await pdf(OrderSummary).toBlob();
    zip.file(`${orderForDownload.name}-Summary.pdf`, summaryBlob);
    // saveAs(summaryBlob, `${orderForDownload.name}-Summary.pdf`);

    //Create Excel Order Form for reps
    if (
      activeSeasons.find((season) => season.key === booking.seasonKey)
        .orderFormVerified
    ) {
      const orderFormURL = activeSeasons.find(
        (season) => season.key === booking.seasonKey
      ).orderForm;

      const articleDataObject = {};
      booking.articles.forEach((article) => {
        articleDataObject[article.id] = article;
      });
      const orderFormAsExcelBuffer = await generateOrderForm(
        orderFormURL,
        articleDataObject,
        orderForDownload
      );
      zip.file(
        `${orderForDownload.name}-OrderForm.xlsx`,
        new Blob([orderFormAsExcelBuffer], {
          type: "application/octet-stream"
        })
      );
      // saveAs(
      //   new Blob([orderFormAsExcelBuffer], {
      //     type: "application/octet-stream"
      //   }),
      //   `${orderForDownload.name}-OrderForm.xlsx`
      // );
    } else {
      // setShowNoOrderFormModal(true);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${orderForDownload.name}-OrderForm_and_Summary.zip`);

    markBookingComplete();
    completeOrder(orderForDownload.id);
    setOrderForDownload();
    setIsLoading(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container>
        <Header>
          <StyledButton
            primary
            fontsize='small'
            onClick={() => setShowCreateOrderModal(true)}
          >
            {` + ${t("new_order")}`}
          </StyledButton>
        </Header>
        <StyledScrollArea>
          {Object.entries(sectionedOrders)?.map(([sectionKey, orders]) => (
            <OrderSection key={sectionKey}>
              <OrderSectionTitle>
                {sectionKey === "repManaged"
                  ? t("rep_managed")
                  : sectionKey === "userGenerated"
                  ? t("buyer_managed")
                  : t("completed_button")}
              </OrderSectionTitle>
              <OrderSectionOrders>
                {orders != null &&
                  Object.values(orders).map((order) => (
                    <OrderCard
                      key={order.id}
                      deviceID={deviceID}
                      currentlyEditingInArticleTab={
                        order.id === articleTabSelectedOrderID
                      }
                      order={order}
                      booking={booking}
                      onSelectEditName={setEditNameOrder}
                      onSelectEditOrder={onEditOrder}
                      onSelectViewSummary={setOrderForSummary}
                      onSelectTransferToBuyer={onTransferToBuyer}
                      onSelectDuplicate={onDuplicateOrder}
                      onSelectDownloadOrder={onDownloadOrder}
                    />
                  ))}
              </OrderSectionOrders>
            </OrderSection>
          ))}
        </StyledScrollArea>
      </Container>
      <CreateEditOrderNameModal
        title={showCreateOrderModal ? t("new_order") : t("change_order_name")}
        type={showCreateOrderModal ? "create" : null}
        show={editNameOrder != null || showCreateOrderModal}
        onClose={() => {
          setEditNameOrder();
          setShowCreateOrderModal(false);
        }}
        orderName={orders?.[editNameOrder?.id]?.name}
        onSaveNewName={onSaveNewName}
      />
      <OrderTabCartModal
        title={t("cart_title")}
        show={orderForCart != null}
        onClose={onCloseCart}
        booking={booking}
        order={orderForCart}
        updateDBOrderQtys={updateDBOrderQtys}
        showRemoveArticleButton={false}
      />
      <OrderSummaryModal
        title={t("order_summary_title")}
        show={orderForSummary != null}
        onClose={() => setOrderForSummary()}
        booking={booking}
        order={orderForSummary}
      />
      <ConfirmModal
        title={t("transfer_booking_order_title")}
        buttonTitle={t("transfer_button")}
        message={t("transfer_booking_order_message")}
        show={orderForTransfer != null}
        onClose={() => setOrderForTransfer()}
        onConfirm={onConfirmTransferToBuyer}
      />
      <ConfirmModal
        title={t("confirm_download")}
        buttonTitle={t("download_button")}
        message={t("confirm_download_message")}
        show={orderForDownload != null}
        onClose={() => setOrderForDownload()}
        onConfirm={onConfirmDownloadOrder}
      />
      {/* This is for duplicating order */}
      <CreateEditOrderNameModal
        title={t("duplicate_order")}
        show={orderForDuplication != null}
        onClose={() => setOrderForDuplication()}
        onSaveNewName={onConfirmDuplicateOrder}
      />
      <UserNoticeModal
        show={showNoOrderFormModal}
        onClose={() => setShowNoOrderFormModal(false)}
        title={t("no_order_form_title")}
        message={t("no_order_form_message")}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.tertiary};
  box-shadow: 0px 2px 5px ${COLORS.secondary};
  z-index: 1;
`;

const StyledButton = styled(Button)`
  /* margin-left: 10px; */
  text-transform: uppercase;
`;

const StyledScrollArea = styled(ScrollArea)`
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 0.3333fr)); */
  display: flex;
  flex-direction: column;
`;

const OrderSection = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const OrderSectionTitle = styled.div`
  /* background: ${COLORS.secondary}; */
  border-bottom: 1px solid ${COLORS.text};
  padding: 10px 10px 0;
  margin-bottom: 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.large};
  text-transform: uppercase;
`;

const OrderSectionOrders = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 0.3333fr));
  gap: 10px;
  padding-bottom: 20px;
`;

export default OrdersTab;
